import React, { useContext } from "react";
import { useLocation } from "@reach/router";
import { Colors } from "../../../commons/Theme";
import icons from "../../Icon/icons";
import IconV2 from "../../Icon/IconV2";
import { StyledShare, StyledShareIcons } from "../style";
import { DataContext } from "../../../contexts/DataContext";

const Share = () => {
  const { pageLabels } = useContext(DataContext);
  const labels = pageLabels.share;
  const location = useLocation();
  const encodedUrl = encodeURIComponent(`https://www.3bee.com${location.pathname}`);

  return (
    <StyledShare>
      <p dangerouslySetInnerHTML={{ __html: labels.title }}/>
      <StyledShareIcons>
        <a href={`https://www.facebook.com/sharer.php?u=${encodedUrl}`} target="_blank" rel="noreferrer">
          <IconV2 icon={icons.facebook} color={Colors.yellow} height="30px"/>
        </a>
        <a href={`https://twitter.com/intent/tweet?url=${encodedUrl}`} target="_blank" rel="noreferrer">
          <IconV2 icon={icons.twitter} color={Colors.yellow} height="30px"/>
        </a>
        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`} target="_blank" rel="noreferrer">
          <IconV2 icon={icons.linkedin} color={Colors.yellow} height="30px"/>
        </a>
      </StyledShareIcons>
    </StyledShare>
  );
};

export default Share;
